import { color, display, space, typography } from 'styled-system';
import styled, { css } from 'styled-components';

import Container from '../Container';
import Grid from '../Grid';
import Link from '../Link';
import Media from '../Media';
import PropTypes from 'prop-types';
import React from 'react';
import defaultTheme from '../../utils/themes';
import jacobsLogo from '../../images/jacobs_logo.svg';
import propTypes from '@styled-system/prop-types';
import { useMemo } from 'react';

const commonStyles = css`
	font-size: 15px;
	line-height: 22px;
	text-decoration: none;
	font-weight: 500;
`;

export const FooterLink = styled(Link)`
	color: rgba(255, 255, 255, 0.699);
	transition: all 0.3s;

	:hover {
		color: white;
	}

	${display}
	${space}
    ${commonStyles}
`;

FooterLink.propTypes = {
	...display.propTypes,
};

FooterLink.defaultProps = {
	display: 'block',
};

const FooterContainer = styled.footer`
	position: relative;

	${color}
	${space}
`;

const Copyright = styled.span`
	${commonStyles}

	color: rgba(255,255,255,0.699);
`;

const Separator = styled.span.attrs({
	children: '|',
})`
	color: white;
	padding: 0 16px;

	${display}
	${commonStyles}
`;

Separator.defaultProps = {
	display: {
		xs: 'none',
		lg: 'inline',
	},
};

const LastParagraph = styled.p`
	margin-top: 3rem;

	${typography}
`;

const Footer = ({ LinkComponent: Link, ...otherProps }) => (
  <FooterContainer {...otherProps}>
    <Container>
      <Link href='/'>
        <Media
          src={jacobsLogo}
          width={'6rem'}
          alt='Jacobs Logo'
          marginLeft={'0px'}
        />
      </Link>
      <Grid
        // ieAlign="space-between"
        gridTemplateRows={useMemo(
          () => ({
            xs: 'repeat(4, auto)',
            lg: 'auto',
          }),
          []
        )}
        gridTemplateColumns={useMemo(
          () => ({
            xs: '1fr',
            lg: 'repeat(4, 1fr)',
          }),
          []
        )}
        gridGap={useMemo(
          () => ({
            xs: '1rem',
            lg: '1rem',
          }),
          []
        )}
      >
        <div>
          <Link
            href='https://goo.gl/maps/u4kkVbQrNgvSNyQRA'
            marginBottom='24px'
          >
            Jacobs Enforcement Ltd <br /> Registered Office : 6 Europa
            Boulevard, Birkenhead, Wirral, CH41 4PE <br /> Registered in England
            No. 15480247
          </Link>
        </div>

        <div>
          <Link href='tel:03456012692'>Tel: 0345 601 2692</Link>
          <Link href='mailto:mail@jacobsenforcement.com'>
            mail@jacobsenforcement.com
          </Link>
        </div>

        <div>
          <Link href='/about' marginBottom='12px'>
            About
          </Link>

          <Link href='/contact' marginBottom='12px'>
            Contact
          </Link>

          <Link href='/privacy-notice' marginBottom='12px'>
            Privacy
          </Link>

          <Link href='/policy' marginBottom='12px'>
            Policy Download
          </Link>

          <Link href='/regulations' marginBottom='12px'>
            Regulations
          </Link>
        </div>

        <div>
          <Link href='/ways-to-pay' marginBottom='12px'>
            Ways to Pay
          </Link>

          <Link href='/pay-now' marginBottom='12px'>
            Pay Now
          </Link>
          <Link href='/submit-proof' marginBottom='12px'>
            Submit Proof of Residency
          </Link>

          <Link href='/login' marginBottom='12px'>
            Account Login
          </Link>
        </div>
      </Grid>

      <LastParagraph
        textAlign={useMemo(
          () => ({
            xs: 'left',
            lg: 'center',
          }),
          []
        )}
      >
        <Copyright>© Copyright {new Date().getFullYear()} Jacobs</Copyright>

        <Separator />

        <Link
          display={useMemo(
            () => ({
              xs: 'block',
              lg: 'inline',
            }),
            []
          )}
          href='/accessibility'
        >
          Accessibility Statement
        </Link>

        <Separator />

        <Link
          display={useMemo(
            () => ({
              xs: 'block',
              lg: 'inline',
            }),
            []
          )}
          href='/cookies'
        >
          Cookies Policy
        </Link>
      </LastParagraph>
    </Container>
  </FooterContainer>
);

Footer.propTypes = {
	...propTypes.color,
	...propTypes.space,

	LinkComponent: PropTypes.elementType.isRequired,
	theme: PropTypes.object,
};

Footer.defaultProps = {
	backgroundColor: '#23244c',
	LinkComponent: FooterLink,
	paddingTop: '50px',
	paddingBottom: '40px',
	theme: defaultTheme,
};

export default Footer;
