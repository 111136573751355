import styled from 'styled-components';
import { compose, height, position, space, width } from 'styled-system';
import propTypes from '@styled-system/prop-types';

const style = compose(
    height,
    position,
    space,
    width
);

const Media = styled.img(style);

Media.propTypes = {
    ...propTypes.height,
    ...propTypes.position,
    ...propTypes.space,
    ...propTypes.width
};

Media.defaultProps = {
    height: 'auto',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    width: 'auto'
};

export default Media;
