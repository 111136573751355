import styled from 'styled-components';
import {
    border,
    color,
    compose,
    space,
    typography,
    flexbox,
    layout,
} from 'styled-system';
import propTypes from '@styled-system/prop-types';

const style = compose(border, color, space, typography, flexbox, layout);

const Notification = styled.p(style);

Notification.propTypes = {
    ...propTypes.border,
    ...propTypes.color,
    ...propTypes.space,
    ...propTypes.typography,
    ...propTypes.flexbox,
    ...propTypes.layout,
};

Notification.defaultProps = {
    backgroundColor: 'lighterred',
    borderColor: 'lightred',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: 1,
    fontWeight: 300,
    marginBottom: 24,
    marginLeft: '0px',
    marginRight: '0px',
    padding: 16,
    color: 'darkred',
    display: 'flex',
};

export default Notification;
