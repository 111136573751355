import styled, { css } from 'styled-components';
import { pathOr } from 'ramda';

const maxWidthFn = ({ theme }) => {

    const maxWidth = pathOr('1280px', ['breakpoints', 'xl'], theme);

    return css`
        max-width: ${maxWidth};
    `;
};

const Container = styled.div`

    margin: 0 auto;
    padding: 0 1rem;

    ${maxWidthFn}
`;

export default Container;
