import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    border,
    color,
    compose,
    display,
    space,
    system,
    textAlign,
    textDecoration,
    textTransform,
    transition,
    typography,
} from 'styled-system';
import propTypes from '@styled-system/prop-types';

// Hack to require the InternalLink if we are in Gatsby and not in Storybook
let InternalLink;
if (!process.env.STORYBOOK_ENV) {
    InternalLink = require('./InternalLink').default;
}

const externalUrlRegex = /^(?:(?:https?)?:\/\/|mailto:|tel:|\/static\/)/;

const ExternalLink = styled.a.attrs(({ href, ...otherProps }) => {
    const isExternal = externalUrlRegex.test(href);

    const addedProps = {
        href,
        ...otherProps,
    };

    if (isExternal && !href.startsWith('mailto:') && !href.startsWith('tel:')) {
        addedProps.target = '_blank';
        addedProps.rel = 'noopener noreferrer';
    }

    return addedProps;
})``;

const UnstyledLink = ({
    children,
    InternalLinkComponent: InternalLink,
    href,
    ...otherProps
}) => {
    const {
        backgroundColor, // eslint-disable-line no-unused-vars
        borderRadius, // eslint-disable-line no-unused-vars
        hoverColor, // eslint-disable-line no-unused-vars
        lineHeight, // eslint-disable-line no-unused-vars
        marginBottom, // eslint-disable-line no-unused-vars
        marginRight, // eslint-disable-line no-unused-vars
        marginTop, // eslint-disable-line no-unused-vars
        paddingBottom, // eslint-disable-line no-unused-vars
        paddingLeft, // eslint-disable-line no-unused-vars
        paddingRight, // eslint-disable-line no-unused-vars
        paddingTop, // eslint-disable-line no-unused-vars
        textAlign, // eslint-disable-line no-unused-vars
        textTransform, // eslint-disable-line no-unused-vars
        ...propsToPass
    } = otherProps;

    const isExternal = externalUrlRegex.test(href);
    const Component = isExternal ? ExternalLink : InternalLink || ExternalLink;

    return (
        <Component href={href} {...propsToPass}>
            {children}
        </Component>
    );
};

UnstyledLink.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string.isRequired,
    InternalLinkComponent: PropTypes.elementType,
};

const hoverColor = system({
    hoverColor: {
        property: 'color',
        scale: 'colors',
    },
});

export const style = compose(
    color,
    border,
    display,
    space,
    textAlign,
    textDecoration,
    textTransform,
    transition,
    typography
);

export const hoverStyle = hoverColor;

const Link = styled(UnstyledLink)`
    cursor: pointer;

    ${style} :hover {
        ${hoverStyle}
    }
`;

Link.propTypes = {
    ...propTypes.border,
    ...propTypes.color,
    ...propTypes.display,
    ...propTypes.space,
    ...propTypes.textAlign,
    ...propTypes.textDecoration,
    ...propTypes.textTransform,
    ...propTypes.transition,
    ...propTypes.typography,

    hoverColor: propTypes.color.color,
};

const MemoizedLink = React.memo(Link);

MemoizedLink.defaultProps = {
    color: 'text-darkblue',
    fontWeight: 700,
    hoverColor: '#5d72db',
    InternalLinkComponent: InternalLink,
    textDecoration: 'none',
};

export default MemoizedLink;
