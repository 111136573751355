import { compose, flexbox, grid, layout, space } from 'styled-system';
import styled, { css } from 'styled-components';

import defaultTheme from '../../utils/themes';
import propTypes from '@styled-system/prop-types';

const composedStyles = compose(
    flexbox,
    grid,
    layout,
    space,
);

const style = css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: ${props => props.ieAlign ? props.ieAlign :'flex-start'};

        ${props => props.ieCardLayout && css`
            >*{
                width: 100%;
                margin: 16px 0;

                
                ${props => props.ieMaxCard && css`
                    max-width: ${`${props.ieMaxCard || 380}px`};
                `}

                :first-child{
                    margin: 0 0 16px;
                }
                
                :last-child{
                    margin: 16px 0 0;
                }
            }
        `
}}
    _:-ms-fullscreen, :root {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: ${props => props.ieAlign ? props.ieAlign :'flex-start'};
        
        ${props => props.ieCardLayout && css`
            >*{
                width: 100%;
                margin: 16px 0;

                
                ${props => props.ieMaxCard && css`
                    max-width: ${`${props.ieMaxCard || 380}px`};
                `}

                :first-child{
                    margin: 0 0 16px;
                }
                
                :last-child{
                    margin: 16px 0 0;
                }
            }
        `}
    }
`;

const Grid = styled.div`
    ${composedStyles}
    ${style}
`;

Grid.propTypes = {
    ...propTypes.flexbox,
    ...propTypes.grid,
    ...propTypes.layout,
    ...propTypes.space
};

Grid.defaultProps = {
    display: 'grid',
    theme: defaultTheme
};

export default Grid;
