import propTypes from  '../../../../node_modules/@styled-system/prop-types';
import textDecoration from './textDecoration';
import textTransform from './textTransform';
import transition from './transition';

export default {

    ...propTypes,
    
    textDecoration,
    textTransform,
    transition
};