import breakpoints, { MOBILE_MEDIA_QUERY } from './breakpoints';

const headerColor = '#253169';
const bodyColor = '#444444';

const theme = {
	name: 'default',

	breakpoints,

	colors: {
		primary: '#092540',
		secondary: '#31AB85',
		white: '#FFFFFF',
		grey: '#f6f9fc',
		darkblue: '#23244c',
		darkblue2: '#00088B',
		lighterred: '#f8d7da',
		lightred: '#f5c6cb',
		darkred: '#721c24',
		'text-darkblue': '#253169',
		'text-grey': '#444444',
		headerColor: '#253169',
		'secondary-hover': '#349c77',
		'primary-hover': '#383b93',
		border: '#c2c2c2',
	},

	fonts: {
		primary: '"Proxima Nova", sans-serif',
	},

	radii: {
		slight: '2px',
		medium: '4px',
	},

	shadows: {
		elevated:
			'-3px -1px 12px -2px rgba(50, 50, 93, 0.05), 1px 5px 7px 0px rgba(0, 0, 0, 0.08)',
		none: 'none',
	},

	sizes: {
		lg: '1200px',
	},

	space: ['16px'],

	navLinksVariants: {
		mobile: {
			position: 'absolute',
			left: '0',
			height: '100vh',
			width: '100%',
			backgroundColor: '#010440',
			padding: '35px 45px',
			textAlign: 'center',
			zIndex: '100',
			width: '100% !important',
		},
		desktop: {
			display: 'flex',
			justifyContent: 'space-between',
			alignContent: 'center',
			alignItems: 'center',
			padding: '0px',
			width: '600px',
		},
		hidden: {
			display: 'none',
		},
	},

	// Typography control
	typography: {
		baseFontSize: '20px',
		baseLineHeight: 1.5,
		scaleRatio: 1.2,
		blockMarginBottom: 0.7,
		headerFontFamily: ['Proxima Nova', 'sans-serif'],
		bodyFontFamily: ['Proxima Nova', 'sans-serif'],
		headerColor,
		headerWeight: 700,
		bodyColor,
		bodyWeight: 400,
		boldWeight: 700,
		overrideStyles: ({ rhythm }) => ({
			ul: {
				marginLeft: 0,
			},
			hr: {
				marginTop: rhythm(2),
				marginBottom: rhythm(3),
			},
			'blockquote > :last-child': {
				marginBottom: 0,
			},
			'th:first-child, td:first-child': {
				paddingLeft: '8px',
			},
			'th:last-child, td:last-child': {
				paddingRight: '8px',
			},
			[MOBILE_MEDIA_QUERY]: {
				// Make baseFontSize on mobile 18px.
				html: {
					fontSize: `${(18 / 16) * 100}%`,
				},
			},
		}),
	},
};

export default theme;
