import 'react-toastify/dist/ReactToastify.css';

import React, { useCallback, useEffect } from 'react';
import { ToastContainer as UnstyledToastContainer } from 'react-toastify';

import CssBaseline from '../CssBaseline';
import Footer from '../Footer';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '../../utils/themes';
import localForage from 'localforage';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { Crisp } from 'crisp-sdk-web';

Crisp.configure('386b205e-69f6-4c1b-aca6-ff0600c70acf', {
  autoload: false,
});

export const ToastContainer = styled(UnstyledToastContainer)`
  max-width: 100%;

  &.Toastify__toast-container {
    width: 500px;
  }

  p {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1rem;
  }

  .Toastify__toast {
    border-radius: 4px;
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
      0 3px 7px -3px rgba(0, 0, 0, 0.3);
    padding: 0.3rem 0.8rem;
  }

  .Toastify__toast--default {
    p {
      color: #444;
    }
  }

  .Toastify__toast--info {
    background-color: #474ab8;

    a {
      color: white;
    }
  }

  @media only screen and (max-width: 480px) {
    &.Toastify__toast-container {
      margin-left: 0;
    }

    .Toastify__toast {
      border-radius: 0;
    }
  }
`;

const Layout = ({ children, theme }) => {
  useEffect(() => {
    async function loadCrisp() {
      await Crisp.load();
    }

    loadCrisp();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {children}

      <Footer />

      <ToastContainer
        autoClose={false}
        closeOnClick={false}
        position='bottom-center'
        draggable={true}
      />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Layout.defaultProps = {
  theme: defaultTheme,
};

export default Layout;
