import { createGlobalStyle, css } from 'styled-components';

import React from 'react';
import Typography from 'typography';

const specificPart = {
  400: ['4838bd/00000000000000003b9b0934', 'n4'],
  500: ['3331e6/00000000000000003b9b0936', 'n5'],
  600: ['27776b/00000000000000003b9b0939', 'n6'],
  700: ['71f83c/00000000000000003b9b093b', 'n7'],
};

const getTypekitUrlByWeight = (weight) => {
  const [part1, part2] = specificPart[weight];
  return `https://use.typekit.net/af/${part1}/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=${part2}&v=3`;
};

const typekitUrl400 = getTypekitUrlByWeight(400);
const typekitUrl500 = getTypekitUrlByWeight(500);
const typekitUrl600 = getTypekitUrlByWeight(600);
const typekitUrl700 = getTypekitUrlByWeight(700);

const CssBaseline = React.memo(createGlobalStyle`
    body{
        overflow: hidden;
        max-width: 100vw;
    }
    @font-face {
        font-family: "Proxima Nova";
        src:
            url(${typekitUrl400}) format("woff2"),
            url(${typekitUrl400}) format("woff"),
            url(${typekitUrl400}) format("opentype");
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: "Proxima Nova";
        src:
            url(${typekitUrl500}) format("woff2"),
            url(${typekitUrl500}) format("woff"),
            url(${typekitUrl500}) format("opentype");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Proxima Nova";
        src:
            url(${typekitUrl600}) format("woff2"),
            url(${typekitUrl600}) format("woff"),
            url(${typekitUrl600}) format("opentype");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Proxima Nova";
        src:
            url(${typekitUrl700}) format("woff2"),
            url(${typekitUrl700}) format("woff"),
            url(${typekitUrl700}) format("opentype");
        font-weight: 700;
        font-style: normal;
    }

    ${({ theme }) => {
      const typography = new Typography({
        name: theme.name,
        ...theme.typography,
      });

      return css`
        ${typography.toString()}
      `;
    }}

    html {
        -webkit-font-smoothing: antialiased;
    }
`);

export default CssBaseline;
